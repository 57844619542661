import React, { createContext, useContext, useReducer, useEffect } from "react"
import axios from "axios"
import { GatsbyBrowser } from "gatsby"
import { AuthCredentials, RegisterCredentials, AuthContextState } from "./type"
const apiURL = process.env.GATSBY_API_URL

const DEFAULT_STATE: AuthContextState = {
  jwt: undefined,
  user: {},
  loggedIn: false,
}

type actionType =
  | { type: "LOGIN" | "REGISTER"; payload: { jwt: string; user: any } }
  | { type: "LOGOUT" }
  | { type: "init_stored"; value: AuthContextState }

const reducer = (
  state: AuthContextState,
  action: actionType
): AuthContextState => {
  switch (action.type) {
    case "init_stored":
      return action.value
    case "LOGIN":
    case "REGISTER":
      const { jwt = undefined, user = {} } = action.payload
      return { ...state, jwt, user, loggedIn: true }
    case "LOGOUT":
      localStorage.removeItem("state")
      return { ...state, jwt: undefined, user: {}, loggedIn: false }
    default:
      return DEFAULT_STATE
  }
}

const AuthContext = createContext<
  [AuthContextState, React.Dispatch<actionType>]
>([DEFAULT_STATE, undefined!])

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)

  useEffect(() => {
    const storedState = localStorage.getItem("state")
    if (storedState && JSON.parse(storedState)) {
      //checking if there already is a state in localstorage
      //if yes, update the current state with the stored one
      dispatch({
        type: "init_stored",
        value: JSON.parse(storedState),
      })
    }
  }, [])

  useEffect(() => {
    if (state !== DEFAULT_STATE) {
      localStorage.setItem("state", JSON.stringify(state))
    }
  }, [state])

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <AuthProvider>{element}</AuthProvider>

const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext)
  const isAuthenticated = state.loggedIn && Object.keys(state.user).length

  const login = async (credentials: AuthCredentials) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local`,
          credentials
        )
        dispatcher({ type: "LOGIN", payload })
        resolve(payload)
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })

  const logout = (callback: () => void) => {
    dispatcher({ type: "LOGOUT" })
    callback()
  }

  const register = async (userData: RegisterCredentials) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local/register`,
          userData
        )
        dispatcher({ type: "REGISTER", payload })
        resolve(payload)
      } catch (error: any) {
        console.log(error.response.data)
        reject(error)
      }
    })

  return { state, isAuthenticated, login, logout, register }
}

export default useAuth
