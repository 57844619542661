module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Benefit Place","short_name":"BenefitPlace","lang":"de","start_url":"/","background_color":"#ffffff","theme_color":"#e10000","theme_color_in_head":false,"display":"standalone","icon":"src/assets/bbselect-favicon.png","crossOrigin":"anonymous","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f5b28f8f456b0b8fb2d59589732c6e70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
